<template>
  <div id="DoctorListID">
    <vs-card>
      <div class="vx-col">
        <div class="vx-row">
          <div class="vx-col lg:w-1/6">
            <img v-if="doctor.ProfileImagePath" :src="baseURL + doctor.ProfileImagePath"
            alt=""
            :class="'doctorImage'"
            :style="{ 'border-radius': '15px' }"
             placeHolderType="doctor">
              <img v-else
            alt=""
            src="@/assets/images/DoctorPlaceHolder.png"
            :class="'doctorImage'"
            :style="{ 'border-radius': '15px' }"
             placeHolderType="doctor">
            <!-- <imageLazy
              :imageStyle="{ float: 'right', 'border-radius': '15px' }"
              :imageClass="'doctorImage'"
              :src="baseURL + doctor.ProfileImagePath"
              placeHolderType="doctor"
            /> -->
          </div>
          <!-- text info -->
          <div class="vx-col lg:w-1/3">

            <!-- name -->
            <!-- <h2>{{baseURL + doctor.ProfileImagePath}}</h2> -->
            <h4
              class="cursor-pointer greenColor"
              @click="$router.push('/DoctorProfile/' + doctor.ID)"
              style="text-decoration: underline"
            >
              {{ $t("Dr") }}. <span v-if=" doctor.CompleteFullName">{{ doctor.CompleteFullName }} </span><span v-else>{{ doctor.Name }}</span>
            </h4>
            <!-- country -->
            <div class="mt-1 fontSizetext">
              {{ $t("Destination") }}:<span
                class="fontMedium fontbold m-1"
                >{{ doctor.Country.Name }}</span
              >
            </div>
            <!-- Speciality -->
            <div class="mt-1 fontSizetext">
              {{ $t("Speciality") }}:<span
                class="fontMedium fontbold m-1"
                style="text-align: justify"
                >{{ doctor.Specialties }}</span
              >
            </div>
            <!-- Title -->
            <div class="mt-1 fontSizetext">
              {{ $t("Title") }}:<span class="fontMedium fontbold m-1">{{
                doctor.Title
              }}</span>
            </div>
            <!-- Certificates -->
            <div v-if="showBiography" class="mt-1 fontSizetext">
              {{ $t("Certificates") }}:<span
                class="fontMedium fontbold m-1 fontSizetext"
                >{{ doctor.Educations }}</span
              >
            </div>
            <!-- Biography -->
            <div v-if="showBiography" class="mt-1 fontSizetext">
              {{ $t("Biography") }}:<span
                class="fontMedium fontbold m-1 fontSizetext"
                >{{ doctor.Bio }}</span
              >
            </div>
          </div>

          <div class="vx-col lg:w-1/3">

               <u @click="viewBiography()" style="font-size:15px">{{ $t("Biography") }}</u>
                <div class="vx-row mt-2 ">
                  <!-- <div class="vx-col lg:w-1/4">
                     <p style="font-size:13px"> {{$t('Rate')}}: </p>
                  </div>
                  <div  class="vx-col lg:w-3/4">
                      <rate :rateValue="doctor.Rate" />
                  </div> -->
                </div>
                 <div class="vx-row mt-2 ">
                  <div class="vx-col lg:w-1/4">
                     <p style="font-size:13px"> {{$t('RecommendationLevel')}}: </p>
                  </div>
                  <div  class="vx-col lg:w-3/4">
                      <rate :rateValue="doctor.RecommendedLevel" />
                  </div>
                </div>

          </div>

          <div class="vx-col lg:w-1/6">
               <div class="ml-5 mt-1 fontSizetext">
                 <span class="greenColor"> {{ $t("Price") }} </span>:
                  <span
                    class="ml-1"
                    style="font-size: 20px; color: black"
                    >{{ doctor.PriceSessionInUSD }} $</span
                  >
                </div>
                <vs-button
                  class="vx-col mt-1"
                  @click="viewAppointment()"
                  v-if="doctor.HaseAppointemts == true && !showAppointments"
                  >{{ $t("OnlineAppointment") }}</vs-button
                >
                <vs-button
                  class="vx-col mt-1"
                  @click="viewAppointment()"
                  v-if="doctor.HaseAppointemts != true && !showAppointments"
                  >{{ $t("RequestAppointment") }}</vs-button
                >
          </div>
        </div>
        <div class="vx-row mr-2 ml-2 vx-row w-full">
          <div class="lg:w-1/2 mt-1 mb-2">
            <v-date-picker
              @update:from-page="pageChange"
              :locale="$vs.rtl ? 'ar' : 'en'"
              :attributes="attrs"
              :min-date="minDate"
              style="width: 100%"
              v-if="showAppointments"
              v-model="appointmentDate"
              @input="getAppointmentSessions"
            />
          </div>
          <div class="lg:w-1/2">
            <h5 class="ml-1" id="SessionSlot" v-if="showAppointments">
              {{ $t("SelectATimeFor") }} {{ appointmentDateStrig }} :
            </h5>

            <div v-if="showAppointments" class="vx-row ml-1">
              <div
                v-for="item in sessions"
                :key="item.name"
                class="lg:w-1/4 mt-5 m-1 p-1"
              >
                <div v-on:click="selectSession(item)">
                  <vx-card
                    class="p-0 m-0"
                    :style="[
                      !item.IsAvailable
                        ? { 'background-color': 'gray' }
                        : item.ID == Session.ID
                        ? {
                            'background-color': '#004477',
                            'font-color': 'white',
                          }
                        : item.IsBlocked
                        ? { 'background-color': 'red', 'font-color': 'white' }
                        : {},
                    ]"
                  >
                    <p
                      v-if="item.DateFrom != null && item.DateTo != null"
                      :style="[
                        item.ID == Session.ID
                          ? { color: 'white' }
                          : item.IsBlocked
                          ? { color: 'white' }
                          : !item.IsAvailable
                          ? { color: 'white' }
                          : {},
                      ]"
                      class="text-center fontBook p-1"
                    >
                      {{ item.DateFrom.slice(11, 16) }} -
                      {{ item.DateTo.slice(11, 16) }}
                    </p>
                  </vx-card>
                </div>
              </div>
            </div>

            <p
              class="m-2 ml-5"
              v-if="
                (sessions.length > 0 &&
                  !(
                    sessions.findIndex(
                      (element) => element.IsAvailable == true
                    ) >= 0
                  )) ||
                (doctor.HaseAppointemts != true && showAppointments)
              "
              style="font-size: 16px"
            >
              {{ $t("onlineAppointmentSpecificDateMessage") }}
              <u style="color: blue">
                <a
                  :href="
                    'mailto:patient.service@doclinia.com' +
                    '?subject=' +
                    'Request an online consultation with Dr.' +
                    doctor.Name +
                    ',Date:' +
                    changeFormatedDate(this.appointmentDate)
                  "
                  >patient.service@doclinia.com</a
                >
              </u>
            </p>

            <div class="vx-row" v-if="Session.ID > 0 && showAppointments">
              <div class="vx-col w-full">
                <div
                  class="mt-8 mb-5 mr-8 flex flex-wrap items-center justify-end"
                >
                  <vs-button :disabled="!Session.ID" @click="chooseSession()"
                    >{{ $t("Procced") }}
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-popup title="" fullscreen :active.sync="showSetSession">
      <SetSession
        v-if="showSetSession"
        @closePop="closeSessionPop"
        :sessionModel="sessionModel"
      ></SetSession>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showSetPayment">
      <SetPayment
        v-if="showSetPayment"
        @closePop="closeSetPaymentPop"
        @closePayNow="showSetPayment = false"
        :sessionModel="sessionModel"
        :updatedPatientSession="updatedPatientSession"
      ></SetPayment>
    </vs-popup>
    <!-- @closePop="OpenMedicalUploadDocument" -->
    <vs-popup fullscreen title="" :active.sync="ShowPatientNote">
      <SetPatientNote
        v-if="ShowPatientNote"
        @closePop="AddPatientNote"
        :sessionModel="sessionModel"
        :updatedPatientSession="updatedPatientSession"
        :documentModel="documentModel"
      ></SetPatientNote>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowLoginPopup">
      <UserNotLogin
        :MessageAction="'BookAnOnlineAppointment'"
        v-if="ShowLoginPopup"
        @closePop="ShowLoginPopup = false"
      ></UserNotLogin>
    </vs-popup>

    <vs-popup
      v-if="ShowPatientUploadDocument"
      title=""
      :active.sync="ShowPatientUploadDocument"
    >
      <UploadPatientMedicalReport
        @closePop="AddPatientNote"
        :documentModel="documentModel"
      ></UploadPatientMedicalReport>
    </vs-popup>

    <vs-popup title="" :active.sync="showCancelPop" v-if="showCancelPop">
      <SuccessPopup
        :Message="$t('CancelBookingAppointmentMessage')"
        @CloseSeccussPopup="CloseSeccussPopup"
      ></SuccessPopup>
    </vs-popup>

    <vs-popup title="" :active.sync="showPayLaterPop" v-if="showPayLaterPop">
      <SuccessPopup
        :Message="$t('paymentLaterHint')"
        @CloseSeccussPopup="ClosePayLaterSeccussPopup"
      ></SuccessPopup>
    </vs-popup>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import OnlinePayment from "@/views/pages/onilnepayment.vue";
import moment from "moment";
import { domain } from "@/gloabelConstant.js";
import UserNotLogin from "@/views/UserNotLogin.vue";
import SetSession from "@/views/appointment/search/SetSession.vue";
import SetPayment from "@/views/appointment/search/SetPayment.vue";
import SetPatientNote from "@/views/appointment/search/SetPatientNote.vue";
import UploadPatientMedicalReport from "@/views/Patient/UploadPatientMedicalReport.vue";
import SuccessPopup from "@/views/pages/register/SuccessPopup.vue";
import imageLazy from "@/components/image_lazy.vue";

export default {
  data() {
    return {
      minDate: new Date(),
      isSelected: false,
      baseURL: domain,
      sessionModel: {},
      patientNote: "",
      showSetSession: false,
      showSetPayment: false,
      showBiography: false,
      showAppointments: false,
      showCancelPop: false,
      showPayLaterPop: false,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
      Session: {},
      ShowPatientUploadDocument: false,
      documentModel: {},
      updatedPatientSession: {},
      ShowLoginPopup: false,
      ShowPatientNote: false,
      monthSessions: [],
      paymentStatus: false,
      search: {
        IsBlocked: false,
      },
    };
  },
  props: {
    doctor: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    attrs() {
      return [
        {
          bar: "green",
          dates: this.monthSessions.map((ob) => ob.DateFrom),
        },
      ];
    },
  },
  components: {
    imageLazy,
    Datepicker,
    OnlinePayment,
    UserNotLogin,
    SetSession,
    SetPayment,
    SetPatientNote,
    UploadPatientMedicalReport,
    SuccessPopup,
  },
  methods: {
    CloseSeccussPopup() {
      this.showCancelPop = false;
    },

    ClosePayLaterSeccussPopup() {
      this.showPayLaterPop = false;
      // this.ShowPatientNote = true;
      this.$router.push({
        name: "BookingSummary",
        params: { Flag: 1, ID: this.updatedPatientSession.ID },
      });
    },
    OpenMedicalUploadDocument(data, isUpload) {
      debugger;
      this.ShowPatientNote = false;
      if (isUpload == true) {
        this.ShowPatientUploadDocument = true;
        this.documentModel.PatientReservationSessionID =
          data.PatientReservationSessionID;
        this.documentModel.DoctorID = data.DoctorID;
        this.documentModel.IsFromSession = true;
      } else {
      }
    },
    closeSessionPop(updatedPatientSession) {
      debugger;
      this.updatedPatientSession = updatedPatientSession;
      this.showSetSession = false;
      debugger;
      if (this.updatedPatientSession == null) {
        debugger;
        // this.showCancelPop = true;
      }

      if (this.updatedPatientSession && this.updatedPatientSession.ID > 0) {
        debugger;
        this.ShowPatientNote = true;

        this.Session = {};
        this.viewAppointment();
      }
      debugger;
    },
    closeSetPaymentPop(ispaymentNow) {
      this.showSetPayment = false;
      window.showAddSuccess();

      debugger;
      if (!ispaymentNow) {
        debugger;
        //ispaymentNow
        this.showPayLaterPop = true;
      } else {
        this.showSetPayment = true;

        this.$router.push({
          name: "BookingSummary",
          params: { Flag: 1, ID: this.updatedPatientSession.ID },
        });
      }
    },

    pageChange(obj) {
      this.sessions = [];
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
      };
      search.To.setDate(search.To.getDate() + 1);
      search.DateTo = moment(search.To).format("LL");
      search.doctorID = this.doctor.ID;
      search.IsBlocked = false;
      this.$store
        .dispatch("DoctorList/SearchDoctorMonthSessions", search)
        .then((res) => {
          debugger;
          this.monthSessions = res.data.Data;
          if (this.monthSessions && this.monthSessions.length > 0) {
            this.doctor.HaseAppointemts = true;
            var index = Math.ceil(this.monthSessions.length / 2);
            var session = this.monthSessions[index];
            this.doctor.PriceSessionInUSD = session.TotalPriceInUSD;

          }
          else{
              this.doctor.HaseAppointemts = false;
            }
        });
    },

    changeFormatedDate(date) {
      return moment(date).format("LL");
    },

    closeSetSession() {
      this.showSetSession = false;
      this.sessionModel = {};
    },
    AddPatientNote() {
      debugger;
      this.ShowPatientNote = false;
      this.showSetPayment = true;
    },

    chooseSession() {
      debugger;
      if (
        !this.$store.state.auth.isUserLoggedIn() ||
        this.$store.state.AppActiveUser.Patient.ID == 0
      ) {
        this.ShowLoginPopup = true;

        return false;
      }
      if (this.Session.IsBlocked == true) {
        window.sessionBlock();
      } else if (!this.Session.IsAvailable == true) {
        window.sessionOldSelected();
      } else if (this.Session.IsAvailable == true) {
        debugger;
        this.sessionModel.Paylater = this.Session.Paylater;
        this.sessionModel.SessionID = this.Session.ID;
        this.sessionModel.DoctorID = this.Session.DoctorID;
        this.sessionModel.DoctorName = this.Session.Doctor.Name;

        this.sessionModel.SessionDate = this.Session.DateFrom;
        this.sessionModel.TimeFrom = this.Session.DateFrom.slice(11, 16);
        this.sessionModel.TimeTo = this.Session.DateTo.slice(11, 16);
        this.sessionModel.doctorImg = this.doctor.ProfileImagePath;
        this.sessionModel.Country = this.doctor.CountryName;
        this.sessionModel.Specialties = this.doctor.Specialties;
        this.sessionModel.Educations = this.doctor.Educations;
        this.sessionModel.MarkupFees = this.Session.MarkupFees;
        this.sessionModel.VAT = this.Session.VAT;
        this.From = new Date(
          "01/01/2007 " + this.Session.DateFrom.slice(11, 16)
        );
        this.To = new Date("01/01/2007 " + this.Session.DateTo.slice(11, 16));

        this.sessionModel.DurationInMinutes = parseInt(
          (Math.abs(
            new Date(this.To.getTime()) - new Date(this.From.getTime())
          ) /
            (1000 * 60)) %
            60
        );
        this.showSetSession = true;
      }
    },
    selectSession(session) {
      this.doctor.PriceSessionInUSD = session.TotalPriceInUSD;
      debugger;
      this.Session = session;
    },
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    viewAppointment() {
      debugger;
      this.showAppointments = !this.showAppointments;
      if (this.showAppointments) {
        this.appointmentDate = new Date();
        this.getAppointmentSessions();
      }
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },

    getAppointmentSessions() {
      debugger;
      this.sessions = [];
      this.Session = {};
      var dateNow = new Date();
      //this.appointmentDate = this.addHours(this.appointmentDate, 1);
      // if (dateNow > this.appointmentDate) {
      //   window.PastDate();
      //   this.appointmentDate = new Date();
      // } else
      {
        debugger;
        this.appointmentDateStrig = moment(this.appointmentDate).format("LL");
        // var searchAppointment = {};
        this.search.DoctorID = this.doctor.ID;
        // searchAppointment.DoctorID = this.doctor.ID;

        // this.appointmentDate = new Date(
        //   this.appointmentDate.setHours(0, 0, 0, 0)
        // );
        // this.appointmentDate = this.addHours(this.appointmentDate, 21);

        this.search.DayUTC = this.appointmentDateStrig;
        this.sessions = [];
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/SearchDoctorSessions", this.search)
          .then((res) => {
            debugger;
            this.sessions = res.data.Data;
            this.$vs.loading.close();
            debugger;
            if (this.sessions == null || this.sessions.length == 0) {
              this.$vs.notify({
                title: this.$t("NoData"),
                text: this.$t("NoDataToshow"),
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
              });
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style>
.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}

#DoctorListID .text-1xl {
  color: #393939;

  font-size: 1rem;
  letter-spacing: 0px;

  opacity: 0.5;
}
#DoctorListID .fontSizetext {
  font-size: 15px !important;
}

/* .vs-popup--content {
  height: calc(100vh - 100px);
} */
#DoctorListID .con-vs-popup .vs-popup {
  width: 100% !important;
  /* height: 100vh; */
}
/* .custCardPad{
   padding: 1.5rem 0 1.5rem 0;
  padding-top: 0.5rem;
  padding-right: 0;
  padding-bottom: 0.5rem;
  padding-left: 0;
} */
#DoctorListID .vx-card {
  padding: 0px !important;
}
#DoctorListID .vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 1rem 0 1rem 0 !important;
}
</style>
