<template>
     <div :style="{'background-image':`url(${require('@/assets/images/home-assets/blueBar.png')})`,'backgroundRepeat': 'round'}" style="width:150%;">
      <div :style="{'padding-top': (height?height:10)+'vh','margin-right':'60%'}"  v-if="title&&!$vs.rtl">
            <p style="transform: rotate(-90deg);color:white;text-transform: uppercase;width:300%;font-size:150%" v-if="!$vs.rtl"> {{$t(title)}}
               <u style="height:2px;width:40px;background:white;margin: 2px;"></u>
            </p>
        </div>

        <div :style="{'padding-top': (height?height:10)+'vh','margin-right':'35%'}"  v-if="title &&$vs.rtl ">
             <p style="transform: rotate(-90deg);color:white;text-transform: uppercase;width:50%; display: block;margin-left: 10px;text-align: center;padding: 10px;font-size:150% " v-if="$vs.rtl" > {{$t(title)}}
               <u style="height:2px;width:40px;background:white;margin: 2px;"></u>
            </p>
        </div>
     </div>
</template>
<script>
export default {
    props:{
        title:String,
        height:Number
    },

}
</script>
