// axios
import axios from "axios";
 //const domain = "http://localhost:58870/"
 //const domain = "http://195.201.197.126:30/";
   //const domain = process.env.VUE_APP_Base_Url
  const domain  ="https://api.doclinia.com/";
   //const domain ="http://109.205.181.0:5555//";


export default axios.create({
  baseURL: domain
  // You can add your headers here
});
